import React from 'react';

const ServicesComponent = (section, active) => (
  <div className="section__component section--services">
    <div className="services__title">
      <h1 className="services__title__head">Services I offer</h1>
      <h4 className="services__title__sub">How can I help you?</h4>
    </div>
    <div className="services--design services__card">
      <h2 className="services__card__title">
        Eye-catching Web Design
      </h2>
      <div className="services__card__img">
        <img
          title="I offer web design services, encompassing page formats, color schemes, font styles, and more"
          src="https://d13vfmq382wx49.cloudfront.net/webp/design-computer-screen.webp"
          title="I offer web design services, encompassing page formats, color schemes, font styles, and more"
          loading="lazy"
        />
      </div>
      <div className="services__card__description">
        The first thing people will notice, before speaking to you, is how you present yourself, and the same goes for your website.
        The colors, fonts, images, as well as where and when you place these items on your site is going to be the first
        thing that makes a visitor decide if it’s worth sticking around to find out more.
        It’s an often overlooked, but extremely important, element in gaining your visitors’ trust, and I can help your website
        stand out and win that trust.
      </div>
    </div>
    <div className="services--applications services__card">
      <h2 className="services__card__title">
        Responsive Web Application Development
      </h2>
      <div className="services__card__img">
        <img
          alt="I build web applications"
          src="https://d13vfmq382wx49.cloudfront.net/webp/application-computer-screen.webp"
          title="I build web applications"
          loading="lazy"
        />
      </div>
      <div className="services__card__description">
        Your front-end application is the most important piece in getting your visitors the information they need and interacting
        with your organization, whether it’s on their computer, phone, or tablet.  It’s also going to dictate how search engines
        decide to rank your website, depending on how easy it is for anyone and everyone to navigate and what information can be accessed.
        I’m an experienced web developer and fluent with javascript and extensions such as the React libraries, as well as common website builders like WordPress and Elementor.
        These tools, alongside modern CSS best practices, are just a subset of the tools I use to bring your website to life.
      </div>
    </div>
    <div className="services--backend services__card">
      <h2 className="services__card__title">
        Backend Server Development
      </h2>
      <div className="services__card__img">
        <img
          alt="I build and maintain backend application and web servers"
          src="https://d13vfmq382wx49.cloudfront.net/webp/python-code-screen.webp"
          title="I build and maintain backend application and web servers"
          loading="lazy"
        />
      </div>
      <div className="services__card__description">
        Whether you’re building a website with automated tools like WordPress, or developing the webserver directly on other
        cloud platforms like AWS and GCP, it’s about processing data and doing so efficiently.  I have spent most of my time working
        with Python and Node.js backends, and continue to learn more every day and as needed for any project.  I can help you with
        a quick WooCommerce store, a python monolith, a set of javascript microservices, as long as it’s the right tool for the job.
      </div>
    </div>
    <div className="services__cta">
      <a
        className="btn"
        href="/contact"
        role="button"
        tabIndex="0"
      >
        What can I help you with? &#x2192;
      </a>
    </div>
  </div>
);

export default ServicesComponent;
