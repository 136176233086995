import React from'react';

import config from '../config.jsx';
import {
  FacebookLogo,
  FiverrLogo,
  LinkedinLogo,
  offeringLogos,
} from './logos.jsx';

const FooterComponent = (props) => (
  <div className="footer">
    <div className="footer__overlay"></div>
    <div className="footer__copyright">
      <div>© 2023 NetScaping LLC</div>
      <div>All rights reserved.</div>
    </div>
    <div className="footer__offering">{offeringLogos}</div>
    <div className="footer__social">
      <div className="footer__social__icons" style={{'whiteSpace': 'nowrap'}}>
        <a title="NetScaping Facebook Page" href="https://www.facebook.com/61550493892619/">
          {FacebookLogo}
        </a>
        <a title="NetScaping LinkedIn Page" href="https://www.linkedin.com/company/netscaping-llc">
          {LinkedinLogo}
        </a>
        {config.availableForWork && (
          <a title="NetScaping Fiverr Page" href="https://www.fiverr.com/justinsousa">
            {FiverrLogo}
          </a>
        )}
      </div>
      <div className="footer__links" style={{'whiteSpace': 'nowrap', 'textAlign': 'right'}}>
        <a href="/privacy">Privacy Policy</a>
        <a href="/sitemap.xml">Sitemap</a>
      </div>
    </div>
  </div>
);

export default FooterComponent;


