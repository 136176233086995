import React, { useEffect } from 'react';
import classnames from 'classnames';

import { throttle, useStateCallback } from '../helpers.jsx';
import config from '../config.jsx';
import AboutComponent from '../sections/about.jsx';
import ContactComponent from '../sections/contact.jsx';
import DefaultComponent from '../sections/default.jsx';
import HomeComponent from '../sections/home.jsx';
import PortfolioComponent from '../sections/portfolio.jsx';
import ServicesComponent from '../sections/services.jsx';

const SCROLL_CUTOFF = 1440;
const SECTIONS = [
  {
    component: HomeComponent,
    path: 'home',
    scrollable: true,
    text: 'Home',
  },
  {
    component: ServicesComponent,
    path: 'services',
    scrollable: true,
    text: 'Services',
  },
  {
    component: PortfolioComponent,
    path: 'portfolio',
    scrollable: true,
    text: 'Portfolio',
  },
  {
    component: AboutComponent,
    path: 'about',
    scrollable: true,
    text: 'About',
  },
  {
    headerOnly: true,
    href: '/contact',
    path: 'contact',
    text: 'Contact',
  },
];

const VALID_PATHS = SECTIONS.reduce((acc, obj) => {acc[obj.path] = true; return acc}, []),
  ORIGINAL_PATH = location.pathname.replace('/', '');

const getTruePath = (p) => {
  const path = p || location.pathname.replace('/', ''),
    navPath = path || SECTIONS[0].path;
  return VALID_PATHS[navPath] ? navPath : SECTIONS[0].path;
};

let scrollTimeout,
    scrollDirection,
    disableScrollListener = false;

const throttledUpdateNav = throttle((updateNavStateFn, updateVisitedSectionsFn) => {
  const currentPath = getTruePath(),
    visibleSection = findSectionInViewport(),
    sectionPath = visibleSection && visibleSection.attributes.id.value;

  if (sectionPath) {
    history.replaceState(null, null, '/' + sectionPath + location.search);
    updateNavStateFn(sectionPath);
    updateVisitedSectionsFn(state => ({...state, [sectionPath]: true}));

    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => {
      if (window.innerWidth > SCROLL_CUTOFF) {
        scrollToSection(sectionPath, {direction: scrollDirection});
      }
    }, 1000);
  }

  return (sectionPath !== currentPath);
}, 100);

const Slideshow = (props) => {
  const {
    navState,
    onNavClick,
    updateNavState,
  } = props;

  const loadedSections = SECTIONS.reduce((acc, section) => {
    return {
      ...acc,
      [section.path]: section.path !== navState
    };
  }, {});
  const [visitedSections, updateVisitedSections] = useStateCallback(loadedSections);

  useEffect(() => {
    const slideshow = document.querySelector('.slideshow');
    const scrollableSections = document.querySelectorAll('.section .section__component');

    const wheelListener = (event) => {
      if (window.innerWidth < SCROLL_CUTOFF) return;
      const currentSection = findSectionInViewport();
      const isSectionScrollable = currentSection.classList.contains('scrollable');
      scrollDirection = (event.deltaX + event.deltaY) > 0 ? 'end' : 'start';
      let totalDelta = event.deltaX + event.deltaY;
      let component, boundingRect;

      if (isSectionScrollable && Math.abs(event.deltaY) > Math.abs(event.deltaX)) {
        component = currentSection.querySelector('.section__component');
        boundingRect = component.getBoundingClientRect();

        const atTop = component.scrollTop - 40 < 0;
        const atBottom = component.scrollTop + 40 > (component.scrollHeight - component.offsetHeight)

        if (!atBottom && boundingRect.left - totalDelta < 0 && totalDelta > 0) {
          totalDelta = Math.min(totalDelta, boundingRect.left);
        } else if (!atTop && boundingRect.left - totalDelta > 0) {
          totalDelta = Math.max(totalDelta, boundingRect.left);
        }
      }

      slideshow.scrollLeft += (totalDelta || 0);
      const isNewPath = throttledUpdateNav(updateNavState, updateVisitedSections);

      if (isNewPath) {
        scrollableSections.forEach(el => {
          el.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        });
      }
    };

    const scrollListener = (event) => {
      if (window.innerWidth >= SCROLL_CUTOFF) return;
      if (disableScrollListener) return;
      const currentSection = findSectionInViewport();
      const isSectionScrollable = currentSection.classList.contains('scrollable');
      scrollDirection = (event.deltaX + event.deltaY) > 0 ? 'end' : 'start';
      let totalDelta = event.deltaX + event.deltaY;
      let component, boundingRect;

      slideshow.scrollLeft += (totalDelta || 0);
      const isNewPath = throttledUpdateNav(updateNavState, updateVisitedSections);

      if (isNewPath) {
        scrollableSections.forEach(el => {
          el.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        });
      }
    };

    slideshow.addEventListener('wheel', wheelListener, true);
    window.addEventListener('scroll', scrollListener, true);

    const scrollableSectionListener = (event, el) => {
      const boundingRect = el.getBoundingClientRect(),
        totalDelta = event.deltaX + event.deltaY,
        skippingOver = (
          (boundingRect.left < -20 && boundingRect.left - totalDelta > 0) ||
          (boundingRect.left > 20 && boundingRect.left - totalDelta < 0)
        );

      if (skippingOver || boundingRect.left < -20 || boundingRect.left > 20) {
        event.preventDefault();
      }
    };

    if (window.innerWidth >= SCROLL_CUTOFF) {
      scrollableSections.forEach((el) => {
          el.addEventListener('wheel', (event) => scrollableSectionListener(event, el), true)
      });
    }

    scrollToSection(navState, {behavior: 'instant', resetScroll: true});
    history.replaceState(null, null, '/' + navState + location.search);
    setTimeout(() => {
      throttledUpdateNav(updateNavState, updateVisitedSections);
    }, !config.debug ? 2750 : 0);
  }, []);

  useEffect(() => {
    const onHashChange = ((pop) => {
      const newPath = location.pathname.replace('/', '') || 'home';

      if (newPath !== navState) {
        updateNavState(newPath);
        updateVisitedSections(state => ({...state, [newPath]: true}));
        scrollToSection(newPath, {resetScroll: true});
        disableScrollListener = true;
        setTimeout(() => {
          disableScrollListener = false;
        }, 1000);
      } else if (pop) {
        history.go(-1);
      }
    });

    const popStateListener = (event) => {
      onHashChange(true);
    };
    window.addEventListener('popstate', popStateListener);

//    const hashChangeListener = window.addEventListener('hashchange', (event) => {
//      onHashChange();
//    });

    const navChangeListener = (event) => {
      onHashChange();
    };
    window.addEventListener('navchange', navChangeListener);

    return () => {
      window.removeEventListener('popstate', popStateListener);
//      window.removeEventListener('hashchange', hashChangeListener);
      window.removeEventListener('navchange', navChangeListener);
    }
  }, [navState]);

  return (
    <div className="slideshow">
      {SECTIONS.filter(section => !section.headerOnly).map((section, idx) => {
        const {
          component,
          path,
          scrollable,
        } = section;

        const props = {
          onNavClick,
          section,
          isVisited: visitedSections[path],
          isOriginalPath: path === ORIGINAL_PATH,
        };
        
        return (
          <div
            className={classnames("section", {scrollable})}
            id={path}
            key={`slideshow_${idx}`}
          >
            {component ? component(props) : DefaultComponent(props) }
          </div>
        );
      })}
    </div>
  );
};

const findSectionInViewport = () => {
  const sectionElements = document.querySelectorAll('.section');
  let rect, lastBeforeMidScreen = sectionElements[0];

  for (let sectionEl of sectionElements) {
    rect = sectionEl.getBoundingClientRect();
    
    if (rect.left < (window.innerWidth / 2) && rect.top < (window.innerHeight / 2)) {
      lastBeforeMidScreen = sectionEl;
    }
  }

  return lastBeforeMidScreen;
};

const scrollToSection = (navPath=null, opts={}) => {
  const section = opts.section || document.querySelector('#' + getTruePath(navPath));
  if (!section) return;

  const {
    behavior,
    direction,
    resetScroll,
  } = opts;
  const slideshow = document.querySelector('.slideshow');

  const component = section.querySelector('.section__component');
  if (component && resetScroll) component.scrollTop = 0;

  document.body.scrollTo({top: section.offsetTop, behavior: behavior || 'smooth'});
  slideshow.scrollTo({top: section.offsetTop, left: section.offsetLeft, behavior: behavior || 'smooth'});
}

export default Slideshow;
export { 
  SECTIONS,
  getTruePath,
  scrollToSection,
};


