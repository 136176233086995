import React from 'react';
import JotFormReact from 'jotform-react';

import RollingLink from '../components/rolling-link.jsx';
import config from '../config.jsx';

const AboutComponent = (props) => {
  const {
    onNavClick,
  } = props;

  return (
    <div className="section__component section--about">
      <div className="about__title--mobile">
        <h1>About Me</h1>
        <h4>My business is your business</h4>
      </div>
      <div className="about__photo">
          <img
            alt="A picture of myself, Justin Sousa, founder and lead developer of NetScaping, in Iceland"
            className="about__photo__img"
            src="https://d13vfmq382wx49.cloudfront.net/webp/justin-sousa-iceland-resized.webp"
            title="Nice to meet you! I'm Justin Sousa, founder and lead developer of NetScaping"
            loading="lazy"
          />
      </div>
      <div className="about__title">
        <h1>About Me</h1>
        <h4>My business is your business</h4>
      </div>
      <div className="about__intro1">
        I’m Justin Sousa, a full-stack developer with a background that extends across a number of fields 
        from designing and building React.js applications; Python web servers; Linux server and database management; and beyond.
        I’m based in New York, helping companies across the nation to grow their online presence and their business.
      </div>
      <div className="about__intro2">
        I’ve spent the last 12 years working alongside the founding teams of startups and small businesses,
        keeping a watchful eye on how a business is built and run off-screen.
        After my initial years with law and e-commerce startups, I brought my skills to healthcare and elderly care facilities,
        and I’m always looking to bring my knowledge to those who need it most.
        Although my professional life has been centered around programming websites and applications from the ground up,
        I've kept up with the needs of my clients.
        Sometimes simple is best, and I'll just make use of platforms like WordPress and WooCommerce to build an online shop in a few days.
      </div>
      <div className="about__intro3">
        In my spare time I enjoy the calm of the outdoors, away from the computer screens we see every day, enjoying a
        good book with our 3 adorable cats, or travelling the world with my wife.
        My recent visits to Scotland and Iceland have been the most amazing experiences of my lifetime.
        And visiting with family in France keeps me on my toes while they transition seamlessly back and forth from English to French,
        helping me make use of my years of Latin classes to learn the language with them.
        Alongside my venture into the world of design I also took on 3D printing to make hobby items and gifts for friends.
        So if you have an idea that you wish you had a 3D printer to make,
        I could be open to firing up the printer and making it a reality, upon request!
      </div>
      <div className="about__intro4">
        All in all, I'm here to help create.  I've spent years making use of every opportunity to create something new, as a programmer, a designer,
        a hobbyist, and a business developer.  Whether you're an individual who needs a hand with their website idea, a small business looking to
        grow your brand, or an established presence looking to expand it even further,
      </div>
      {config.availableForWork && (
        <>
          <div className="about__contact">
            <RollingLink
              classes="speedy"
              href="/contact"
              id="contact-me"
    /*          onClick={event => onNavClick(event, 'contact')} */
              text="I’m here to help &#x2192;"
            />
          </div>
          <div className="about__jotform">
            <JotFormReact formURL="https://form.jotform.com/232324792144151" loading="lazy" />
          </div>
        </>
      )}
    </div>
  );
};

export default AboutComponent;


