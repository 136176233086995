import React from 'react';
import classnames from 'classnames';


const RollingLink = (props) => {
  const {
    classes,
    href,
    onNavClick,
    path,
    text,
    splitWords,
    ...attrs
  } = props;

  let textArray;
  if (splitWords) {
    textArray = text.split(' ').reduce((acc, word, idx) => (
      idx === 0 ? [...acc, word] : [...acc, ' ', word]
    ), []);
  } else {
    textArray = Array.from(text);
  }

  const elements = textArray.map((letter, idx) => (
    <span
      key={`rl_${idx}`}
      style={{'--index': idx}}
    >
      {letter !== ' ' ? letter : '\u00A0'}
    </span>
  ));

  return (
    <a
      className={classnames('link', 'rolling-link', classes)}
      href={href || ('/' + path)}
      onClick={(onNavClick && !href) ? ((event) => onNavClick(event, path)) : undefined}
      title={text}
      {...attrs}
    >
      <div>{elements}</div>
      <div>{elements}</div>
    </a>
  );
};

export default RollingLink;



