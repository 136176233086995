import React from 'react';

const toggleScroller = (event, elId) => {
  const element = document.getElementById(elId),
    workItems = document.querySelectorAll('.portfolio__work-item');

  workItems.forEach(el => {
    el.classList.remove('active');
  });

  if (element) {
    element.classList.add('active');
  }
};

document.addEventListener('click', (event) => {
  toggleScroller(event, null);
});

const PortfolioComponent = (props) => {
  const {
    isVisited,
    section,
  } = props;

  return (
    <div className="section__component section--portfolio">
      <h1 className="portfolio__title">Selected Works</h1>
      <div className="portfolio__work-item">
        <div className="work-item__title">
          <a href="https://www.deviceguruplus.com" target="blank" className="link">
            <h2>Device Guru Plus</h2>
          </a>
        </div>
        <a className="work-item__img" href="https://www.deviceguruplus.com" target="blank">
          <img
            alt="Device Guru Plus home page thumbnail"
            src="https://d13vfmq382wx49.cloudfront.net/webp/device-guru-wide-thumbnail.webp"
            title="Device Guru Plus home page thumbnail"
            loading="lazy"
          />
        </a>
        <div className="work-item__description">
          Device Guru Plus is a premier tech company that specializes in the design and assembly of cutting-edge gaming PCs.
          It also offers tech-repair services on multiple devices such as smartphones, tablets, game consoles, laptops, and desktop computers.
          <br /><br />
          My client was looking for a new design for their website with a simple user interface for the sale of custom and pre-built PCs,
          as well as providing technical repairs for customers across the country. Focus of the design was mainly to maintain the brand
          color scheme and to optimize the website for SEO purposes.  Device Guru Plus now has a functional e-commerce website based on my
          designs that is 100% unique, easy to browse, mobile-friendly, and SEO-optimized.
        </div>
      </div>
      <div id="adama" className="portfolio__work-item portfolio__work-item--scroller">
        <a className="work-item__title" onClick={(event) => toggleScroller(event, 'adama')}>>
          <h2>Adama Law Offices</h2>
        </a>
        <a className="work-item__img">
          <img
            alt="Adama Law Offices home page thumbnail"
            src="https://d13vfmq382wx49.cloudfront.net/webp/adama-wide-thumbnail.webp"
            onClick={(event) => toggleScroller(event, 'adama')}
            title="Adama Law Offices home page thumbnail"
            loading="lazy"
          />
          <div className="work-item__img__scroller">
            <img
              alt="Adama Law Offices home page full length"
              src="https://d13vfmq382wx49.cloudfront.net/webp/adama-scroller.webp"
              title="Adama Law Offices home page full length"
              loading="lazy"
            />
          </div>
        </a>
        <div className="work-item__description">
          Adama Law Offices is another one of my creations, appealing to clients that don't need a complex e-commerce application, but just want to
          expand the visibility of their business to the online community.  What they need is for potential customers to see what my client can offer
          them, and build their trust in the business' reputation.
          <br /><br />
          This is a page design built using Figma with the intention of showing what I can offer in designing a website that has the potential
          to attract, inform, and acquire new customers for their businesses and organizations.  The design is made to be simple, but aesthetically pleasing
          to the eye, and immediately build trust with the help of their previous clients' experiences.  Visitors are quickly given the opportunity to see
          and learn about the organization's people, their history, and how they conduct their business, which is especially useful for those who would
          mainly be conducting business in person.  You may have noticed that as soon as that trust and personal connection is built, that visitors are
          immediately given a very visible and quick way to get in touch, which is what makes this one of my favorite designs for lead generation.
        </div>
      </div>
      <div id="chatapp" className="portfolio__work-item portfolio__work-item--scroller">
        <a className="work-item__title" onClick={(event) => toggleScroller(event, 'chatapp')}>>
          <h2>WhisperChat</h2>
        </a>
        <a className="work-item__img">
          <img
            alt="WhisperChat home page thumbnail"
            onClick={(event) => toggleScroller(event, 'chatapp')}
            src="https://d13vfmq382wx49.cloudfront.net/webp/whisperchat-landscape-thumbnail.webp"
            title="WhisperChat home page thumbnail"
            loading="lazy"
          />
          <div className="work-item__img__scroller">
            <img
              alt="WhisperChat home page full length"
              src="https://d13vfmq382wx49.cloudfront.net/webp/chat-app-scroller.webp"
              title="WhisperChat home page full length"
              loading="lazy"
            />
          </div>
        </a>
        <div className="work-item__description">
          WhisperChat's website design is another fictitious creation of mine, built with Figma, for the launch of a new mobile application.  The design 
          was fun to create, and can be thought of as the SuperBowl half-time ad version of website home pages.
          <br /><br />
          As a brand new product launch, the idea is to immediately catch the visitor's attention with a simple design that focuses on a ridiculous,
          but hard to forget joke using the app as a venue (somehow my wife still remembers this and brings it up every so often).  An important part
          of the website design for a completely new brand is that it is easy for visitors to follow through and sign up for or purchase your
          product;  This is accomplished by providing customers with a call-to-action button throughout the process of learning what the product
          can do for them.  Even more important is that these buttons aren't too in-your-face as to be annoying, and even use different text, so as not
          to be boring.  With these principles in mind, I can help you draw customers to your new brand with a 100% unique design catered to your business.
        </div>
      </div>
      <div className="portfolio__work-item">
        <div className="work-item__title">
          <a href="https://bs-cafe.netscaping.com" target="blank" className="link">
            <h2>BlueSky Cafe</h2>
          </a>
        </div>
        <a
          className="work-item__img"
          href="https://bs-cafe.netscaping.com"
          target="blank"
        >
          <img
            alt="BlueSky Cafe home page thumbnail"
            src="https://d13vfmq382wx49.cloudfront.net/webp/bs-cafe-landscape-thumbnail.webp"
            title="BlueSky Cafe home page thumbnail"
            loading="lazy"
          />
        </a>
        <div className="work-item__description">
          BlueSky Cafe, while it is not a brick and mortar shop full of the wonderful aroma of freshly brewed coffee, has a website that is a very real
          creation of my own design.
          <br /> <br />
          In the process of establishing my presence as a freelance developer and web designer it became apparent that potential clients would often
          be using or request a website built using the WordPress platform.  I needed to be able to keep up with my clients' needs, so I set out to
          add a WordPress server to my own AWS machines to experiment with, ease the development process, and potentially host new websites for future clients.
          I was happy to learn that a WordPress plugin, ReactPress, would allow me to use my years of experience in React.js, CSS, and API development inside of
          WordPress templates.  BlueSky Cafe's website is a unique design of my own, implemented as a functional, mobile-friendly, e-commerce application
          backed by WooCommerce's inventory management interface via its built-in API. 
        </div>
      </div>
      <div id="rincewind" className="portfolio__work-item">
        <a className="work-item__title" onClick={(event) => toggleScroller(event, 'rincewind')}>>
          <h2>Rincewind Medical</h2>
        </a>
        <a className="work-item__img">{/* href="https://www.drrincewind.com" target="blank">*/}
          <img
            alt="Rincewind Medical home page thumbnail"
            src="https://d13vfmq382wx49.cloudfront.net/webp/drrincewind-square-thumbnail.webp"
            title="Rincewind Medical home page thumbnail"
            loading="lazy"
          />
        </a>
        <div className="work-item__description">
          Dr Rincewind's Medical Offices is a simple and straightforward design meant to mimic the clean feeling of a doctor's office.
          Smooth animations bring the website to life with welcoming slide-ins as each section is scrolled over, offering more and more information
          about the office, its staff, and their services and accomplishments.
          <br /><br />
          Local doctor's offices can open and get a quick start in building their online presence, which is all but a requirement for gaining
          the trust of potential patients when they search for medical professionals. Built-in contact and scheduling can quickly turn a
          Google search into a new patient walking through the door. The website is built with Wordpress' Gutenberg plugin and hosted on
          NetScaping's in-house Wordpress hosting, which I encourage my clients to take advantage of for its speed and personal customer service.
        </div>
      </div>
      <div className="portfolio__cta">
        <a
          className="btn"
          href="/contact"
          role="button"
          tabIndex="0"
        >
          Let's build your website together &#x2192;
        </a>
      </div>
    </div>
  );
};

export default PortfolioComponent;
