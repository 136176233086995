import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import RollingLink from '../components/rolling-link.jsx';
import config from '../config.jsx';

const fadeImages = config.slides;

const HomeComponent = (props) => {
  const {
    isVisited,
    onNavClick,
    section,
    isOriginalPath,
  } = props;

  let treeImg;
  if (!isOriginalPath) {
    treeImg = (
      <img
        alt="Growing a tree, just like building a brand"
        className="tree-container__gif"
        title="Growing a tree, just like building a brand"
        src="https://d13vfmq382wx49.cloudfront.net/webp/ns-tree-final-frame.webp"
      />
    );
  } else if (isVisited) {
    treeImg = (
      <video
        alt="Growing a tree, just like building a brand"
        className="tree-container__gif"
        title="Growing a tree, just like building a brand"
        src="https://d13vfmq382wx49.cloudfront.net/ns-tree-growth.webm"
        autoPlay
        muted
      />
    );
  } else {
    treeImg = (
      <img
        alt="Growing a tree, just like building a brand"
        className="tree-container__gif"
        title="Growing a tree, just like building a brand"
      />
    );
  }
 
  return (
    <div className="section__component section--home">
      <div className="home__hi">
        <h1>Hi, I'm Justin</h1>
        <h4>And this is NetScaping Web Development</h4>
      </div>
      <h3 className="home__create">I create websites</h3>
      <h3 className="home__build">I build brands</h3>
      <h3 className="home__idea">
        <RollingLink 
          text="Do you have an idea I can help you grow?"
          splitWords="true"
          href="/contact"
        />
      </h3>
      <div className="home__slides">
        <Fade>
          {fadeImages.map(obj => (
            <a key={obj.title} href="/portfolio" className="home__slide-container" onClick={(event) => onNavClick(event, 'portfolio')}>
              {obj.isDemo && (
                <div className="home__slide-container__demo-label">Demo</div>
              )}
              <img
                alt={obj.title} className="home__slide-container__img"
                src={obj.url}
                title={obj.title}
                loading="lazy"
              />
            </a>
          ))}
        </Fade>
      </div>
      <div className="home__tree tree-container">
        {treeImg}
      </div>
    </div>
  );
};

export default HomeComponent;
