import React from 'react';

const ContactComponent = (props) => {
  const {
    isVisited,
    section,
  } = props;

  return (
    <div className="section__component section--contact">
      <div>
        { section.title }
      </div>
      <div className="tree-container">
        <img className="tree-container__gif" src={isVisited ? "https://d13vfmq382wx49.cloudfront.net/ns-tree.gif" : undefined} />
        <div className="redesign-text">
          Redesign Pending
        </div>
      </div>
    </div>
  );
};

export default ContactComponent;
