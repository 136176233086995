import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

const LandingDropOverlayComponent = (props) => {
  const [dropState, updateDropState] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const contactDropEl = document.querySelector('.drop-container')
      if (contactDropEl) {
        contactDropEl.style.position = 'relative';
      }
      updateDropState(true);
    }, props.timeout);
  }, []);

  return (
    <div className={classnames('landing-container', { 'landing-container--active': dropState })}>
      <div className={classnames('landing__overlay', { 'landing__overlay--active': dropState })} />
      <div className={classnames('landing__drop drop', { 'landing__drop--active': dropState })} />
      <div className={classnames('landing__wave wave', { 'landing__wave--active': dropState })} />
    </div>
  );
};

export default LandingDropOverlayComponent;
