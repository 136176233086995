import { useCallback, useEffect, useRef, useState } from 'react';

const debounce = (fn, timeout=500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, timeout);
  };
};

const throttle = (fn, timeout=500) => {
  let timer, throttlePause = false;
  return (...args) => {
    if (throttlePause) return;
    throttlePause = true;

    timer = setTimeout(() => {
      throttlePause = false;
      console.log('unpause');
    }, timeout);

    return fn.apply(this, args);
  };
};

function useStateCallback(initialState) {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null); // init mutable ref container for callbacks

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb; // store current, passed callback in ref
    setState(state);
  }, []); // keep object reference stable, exactly like `useState`

  useEffect(() => {
    // cb.current is `null` on initial render, 
    // so we only invoke callback on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
}

export { 
  debounce,
  throttle,
  useStateCallback,
};
