// import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';

import config from './config.jsx';
import FooterComponent from './components/footer.jsx';
import HeaderComponent from './components/header.jsx';
import LandingDropOverlayComponent from './components/landingDropOverlay.jsx';
import RollingLink from './components/rolling-link.jsx';
import Slideshow, {
  SECTIONS,
  getTruePath,
} from './components/slideshow.jsx';

import '../styles/main.scss';


const MainComponent = () => {
  const originPath = getTruePath();
  const [navState, updateNavState] = useState(originPath);
  const [bannerState, updateBannerState] = useState(false);

  const onNavClick = (event, path) => {
    window.history.pushState({}, '', '/' + path + location.search);
    window.dispatchEvent(new CustomEvent('navchange', { detail: path }));
    updateNavState(path);

    event.preventDefault();
  };

  useEffect(() => {
    if (config.bannerText) {
      setTimeout(() => {updateBannerState(true)}, 1000);
      setTimeout(() => {updateBannerState(false)}, 8000);
    }
  }, []);

  // TODO remove duplicate ns-main
  return (
    <div className="ns-main">
      <HeaderComponent
        navState={navState}
        onNavClick={onNavClick}
        sections={SECTIONS}
      />
      <div className={classnames('floating-banner',  {'floating-banner--active': bannerState})}>
        {config.bannerText}
      </div>
      <Slideshow
        navState={navState}
        onNavClick={onNavClick}
        updateNavState={(nav) => updateNavState(nav)}
      />
      <FooterComponent />
      {!config.debug && (
        <LandingDropOverlayComponent timeout="2000" />
      )}
    </div>
  );
};

window.addEventListener('load', () => {
  ReactDOM.render(<MainComponent />, document.querySelector('.ns-main'));
});



