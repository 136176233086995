import React from'react';
import classnames from 'classnames';

import config from '../config.jsx';
import RollingLink from './rolling-link.jsx';

const HeaderComponent = (props) => {
  const {
    navState,
    onNavClick,
    sections,
  } = props;

  const renderNav = () => (
    <div className="ns-nav">
      {sections.map((urlObj) => {
        const {
          href,
          path,
          text,
        } = urlObj;

        if (!text) return null;

        return (
          <RollingLink
            classes={classnames('ns-nav__link', `ns-nav__link--${path}`, { active: path === navState })}
            href={href}
            key={`ns-nav__${path}`}
            onNavClick={onNavClick}
            path={path}
            text={text}
          />
        );
      })}
    </div>
  );

  return (
    <div className="header">
      <a
        className="header__logo"
        href="/home"
        onClick={!!onNavClick ? (event) => onNavClick(event, 'home') : undefined}
        title="NetScaping"
      >
        <div className="header__logo__img">
          <img
            alt="NetScaping LLC Logo and Home link"
            src="https://d13vfmq382wx49.cloudfront.net/webp/ns-tree-icon-square.webp"
            title="NetScaping LLC"
            loading="lazy"
          />
        </div>
        <h1 className="header__logo__text">
          <span className="header__logo__net">Net</span>
          <span className="header__logo__scaping">Scaping</span>
        </h1>
      </a>
      {renderNav()}
      {config.availableForWork && (
        <a
          className="header__availability btn pulsing"
          href="/contact"
          role="button"
          tabIndex="0"
        >
          Available for freelance work
        </a>
      )}
    </div>
  );
};

export default HeaderComponent;


